import * as React from 'react';
import notFound from '@assets/lottie/not_found.json';

import Lottie from 'lottie-react';

import { ButtonFirstAction } from '@components/UI/Buttons';

import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

const style = {
  height: 400,
};

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <main className='flex flex-col justify-center items-center h-[calc(100vh-130px)]'>
      <Lottie animationData={notFound} loop={true} style={style} />
      <h1>{t('error')} 404</h1>
      <p>{t('errorOccuredContent')}</p>
      <p>{t('errorOccured')}</p>
      <ButtonFirstAction
        onClick={() => {
          navigate('/');
        }}
        customClass='my-4'
      >
        {t('backToHome')}
      </ButtonFirstAction>
    </main>
  );
};

export default NotFoundPage;
